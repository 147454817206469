<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :onExport="isGov?null:onExport" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="话题名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入话题名称"></el-input>
        </el-form-item>
        <el-form-item label="创建者：" prop="author_name">
          <el-input v-model="form.author_name" placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item v-if="!isGov" label="所属小组：" prop="group_id">
          <el-select v-model="form.group_id" placeholder="请选择所属小组">
            <el-option v-for="item in group_options" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-else label="所属小组：" prop="group_name">
          <el-select v-model="form.group_name" placeholder="请选择所属小组">
            <el-option v-for="item in group_options" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-prop="create_start_time"
          :start-time.sync="form.create_start_time" end-prop="create_end_time"
          :end-time.sync="form.create_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { categoryOptions } from "../../api/category-list";
import { groupOptions } from "../../api/group/group-list";
import { topicsExport } from "../../api/topic-admin/list";
import { getGovGroups } from "@/modules/gov/api/post-library";
export default {
  name: 'topic-list-filter',
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
    isGov: Boolean,
  },
  data() {
    return {
      rules: {},
      group_options: [],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
    // 导出
    onExport() {
      const postData = { ...this.form, page: this.page };
      return topicsExport(postData)
        .then()
        .catch(() => {});
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      if (this.isGov) {
        getGovGroups().then(res => {
          this.group_options = [{ id: -1, name: "全部" }, ...res.data];
        })
        return
      }
      groupOptions()
        .then((res) => {
          this.group_options = [{ id: -1, name: "全部" }, ...res.data];
        })
        .catch((err) => {});
    });
    // categoryOptions()
    //   .then((res) => {
    //     this.categoryOptions = res.data;
    //   })
    //   .catch((err) => {});
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
