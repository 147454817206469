import { api } from "@/base/utils/request";

export const getGovPosts = (data) => {
  return api({
    url: '/admin/gov/post/index',
    method: 'post',
    data
  })
}

/* 所有小组 */
export const getGovGroups = () => {
  return api({
    url: '/admin/gov/post/allGroups',
    method: 'post',
  })
}

/* 所有话题 */
export const getGovTopics = () => {
  return api({
    url: '/admin/gov/post/allTopics',
    method: 'post',
  })
}
