import api from "@/base/utils/request";
import download from "@/base/utils/download";

// 获取品牌列表
export const groupList = data => {
  return api({
    url: "/admin/circle/group/index",
    method: "post",
    data
  });
};

// 更新排序
export const updateSort = data => {
  return api({
    url: "/admin/circle/group/changeSort",
    method: "post",
    data
  });
};
// 获取上级组织列表
export const getOrganizationsTree = data => {
  return api({
    url: "/admin/cyc/Organization/OrganizationsTree",
    method: "post",
    data
  });
};
// 更新推荐
export const updateRecommend = data => {
  return api({
    url: "/admin/circle/group/changeRecommend",
    method: "post",
    data
  });
};

// 更新显示
export const updateShow = data => {
  return api({
    url: "/admin/circle/group/changeShow",
    method: "post",
    data
  });
};

// 删除移入回收站
export const softDelete = data => {
  return api({
    url: "/admin/circle/group/softDelete",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/circle/group/delete",
    method: "post",
    data
  });
};

// 回收站还原数据
export const putBack = data => {
  return api({
    url: "/admin/circle/group/putBack",
    method: "post",
    data
  });
};

// 获取文章/活动列表
export const postedList = data => {
  return api({
    url: "/admin/circle/group/posted",
    method: "post",
    data
  });
};

// 获取已选择文章/活动列表
export const HaveChosenList = data => {
  return api({
    url: "/admin/circle/group/HaveChosen",
    method: "post",
    data
  });
};

// 添加文章/活动
export const AddList = data => {
  return api({
    url: "/admin/circle/group/add",
    method: "post",
    data
  });
};

// 删除文章/活动
export const DeleteList = data => {
  return api({
    url: "/admin/circle/group/deletegroup",
    method: "post",
    data
  });
};

// 分类下拉选项
export const AllSource = data => {
  return api({
    url: "/admin/circle/category/all",
    method: "post",
    data
  });
};

// 小组选项列表
export const groupOptions = data => {
  return api({
    url: "/admin/circle/group/all",
    method: "post",
    data,
    notCancel: true
  });
};

// 保存小组配置
export const saveConfig = data => {
  return api({
    url: "/admin/circle/group/saveConfig",
    method: "post",
    data
  });
};

//列表导出
export const groupsExport = data => {
  return download({
    url: "/admin/circle/group/document",
    method: "post",
    data,
    download: true
  });
};