/*
 * @Author: dongjia
 * @Date: 2021-08-05 15:27:52
 * @LastEditTime: 2021-08-12 16:23:29
 * @LastEditors: aleaner
 * @Description: 专题分类相关接口
 * @FilePath: \saas-admin-vue\src\modules\community-admin\api\category-list.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

// 获取分类列表
export const categoryList = data => {
  return api({
    url: "/admin/circle/category/index",
    method: "post",
    data
  });
};

// 保存新增或编辑分类
export const saveCategory = data => {
  return api({
    url: "/admin/circle/category/save",
    method: "post",
    data
  });
};

// 更新显示状态
export const updateShow = data => {
  return api({
    url: "/admin/circle/category/changeShow",
    method: "post",
    data
  });
};

// 更新排序
export const updateSort = data => {
  return api({
    url: "/admin/circle/category/changeSort",
    method: "post",
    data
  });
};

// 转移分类
export const transferCategory = data => {
  return api({
    url: "/admin/circle/category/move",
    method: "post",
    data
  });
};

// 真删除
export const del = data => {
  return api({
    url: "/admin/circle/category/delete",
    method: "post",
    data
  });
};

// 分类下拉选项
export const categoryOptions = data => {
  return api({
    url: "/admin/circle/category/all",
    method: "post",
    data
  });
};