<!--
 * @Author: dongjia
 * @Date: 2021-08-13 15:55:30
 * @LastEditTime: 2022-04-18 10:42:45
 * @LastEditors: aleaner
 * @Description: 添加/编辑话题信息弹窗
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\community-admin\components\topicAdmin\AddTopic.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog :title="topicData.id?'编辑':'新增'" :visible.sync="openDialog" width="500px" :show-close="true"
    class="dialog-vertical" @close="cancel('topicConfig')">
    <el-form size="medium" :model="topicData" :rules="rules" ref="topicConfig" label-width="90px" @submit.native.prevent>
      <el-form-item label="话题名称：" prop="name">
        <el-input style="width: 80%" v-model="topicData.name" placeholder="请填写话题名称"></el-input>
      </el-form-item>
      <!-- <el-form-item label="话题分类：" prop="category_id">
        <el-select style="width: 80%" v-model="topicData.category_id"
          :disabled="!categoryOptions.length || categoryOptionsLoading"
          :placeholder="categoryOptionsLoading?'选项加载中...':!categoryOptions.length?'暂无分类可选':'请选择话题分类'">
          <el-option v-for="item in categoryOptions" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="选择小组：" prop="with_groups">
        <el-select style="width: 80%" v-model="topicData.with_groups"
          :disabled="!groupOptions.length || groupOptionsLoading" multiple collapse-tags
          :placeholder="groupOptionsLoading?'选项加载中...':!groupOptions.length?'暂无小组可选':'请选择小组'"
          @change="withGroupsChange">
          <el-option v-for="item in groupOptions" :disabled="$route.params.id === item.id" :key="item.id"
            :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <div class="form-tip" v-if="$route.params.id">提示：默认选中当前小组，无法取消</div>
      </el-form-item>
      <el-form-item label="话题简介：" prop="description">
        <el-input style="width: 80%" v-model="topicData.description" type="textarea" :rows="4"
          placeholder="请填写话题名称"></el-input>
      </el-form-item>
      <!-- <el-form-item label="图片：" prop="icon">
        <DefaultImageSelector v-model="topicData.icon" :DefaultImage="DefaultImg" :width="80" :height="80">
          <p slot="info" class="info-title">建议尺寸为 80*80</p>
        </DefaultImageSelector>
      </el-form-item>
      <el-form-item label="推荐：" prop="is_recommend">
        <el-radio v-model="topicData.is_recommend" :label="1">推荐</el-radio>
        <el-radio v-model="topicData.is_recommend" :label="0">不推荐</el-radio>
      </el-form-item>
      <el-form-item label="显示：" prop="is_show">
        <el-radio v-model="topicData.is_show" :label="1">显示</el-radio>
        <el-radio v-model="topicData.is_show" :label="0">不显示</el-radio>
      </el-form-item> -->
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('topicConfig')" :loading="saveLoading">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveTopic } from "../../api/topic-admin/list";
import { groupOptions } from "../../api/group/group-list";
import DefaultImageSelector from "@/modules/common/components/DefaultImageSelector.vue";
export default {
  components: { DefaultImageSelector },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Data: {
      type: Object,
    },
    groupID: {
      type: [String, Number],
      default: 0,
    },
    // 弹窗
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: "请填写话题名称", trigger: "blur" }],
        // category_id: [
        //   { required: true, message: "请选择话题分类", trigger: "change" },
        // ],
      },
      categoryOptions: [],
      groupOptions: [],
      saveLoading: false,
      categoryOptionsLoading: false,
      groupOptionsLoading: false,
      DefaultImg:
        window.serverConfig.VUE_APP_ADMINURL +
        "/cyc/images/admin/company_logo.png",
    };
  },
  watch: {
    dialogOpen(val) {
      if (val) {
        this.$refs.topicConfig.resetFields();
        this.$nextTick(() => {
          this.topicData = { ...this.Data };
        });
      }
    },
  },
  created() {
    this.categoryOptionsLoading = true;
    this.groupOptionsLoading = true;
  },
  mounted() {
    groupOptions()
      .then((res) => {
        this.groupOptions = res.data;
        this.groupOptionsLoading = false;
      })
      .catch((err) => {
        this.groupOptionsLoading = false;
      });
  },
  methods: {
    // 选择小组更改
    withGroupsChange(val) {
      if (
        this.$route.params.id &&
        !this.topicData.with_groups.includes(this.$route.params.id)
      ) {
        this.topicData.with_groups.unshift(this.$route.params.id);
        this.$message.info("如要删除，请打开选项取消勾选");
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          saveTopic(this.topicData)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.$refs[formName].resetFields();
              this.openDialog = false;
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    topicData: {
      get() {
        return this.Data;
      },
      set(val) {
        this.$emit("update: updateData", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}

.dialog-vertical {
  ::v-deep.el-input--medium .el-input__inner {
    height: 36px !important;
  }
}

.info-title {
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 30px;
}
</style>
