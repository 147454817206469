<!--
 * @Author: dongjia
 * @Date: 2021-08-16 10:40:16
 * @LastEditTime: 2022-03-28 16:18:28
 * @LastEditors: aleaner
 * @Description: 话题列表页面
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\community-admin\views\topic-admin\list.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page">
    <el-button
      class="top-btn"
      type="primary"
      size="small"
      @click="handleAddTopic"
      >新增
    </el-button>
    <list-filter
      :style="filterForm.group_id !== -1 ? 'margin-bottom: 20px' : ''"
      :filterForm="filterForm"
      :page="pageData.current_page"
      :uploadFilter="ok"
    />
    <list-tabs
      v-if="filterForm.group_id === -1"
      v-model="filterForm.tab"
      :tabs="tabArr"
      :isLoading="loading"
      @tab-click="handleClick"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn">
        <el-button
          v-if="['checking', 'uncheck'].includes(filterForm.tab)"
          size="small"
          @click="handleChecked"
        >
          通过
        </el-button>
        <el-button
          v-if="filterForm.tab === 'checking'"
          size="small"
          @click="handleNoChecked"
        >
          不通过
        </el-button>
        <el-button type="danger" size="small" @click="handleDel"
          >删除
        </el-button>
        <el-button
          v-if="filterForm.tab === 'deleted'"
          type="success"
          size="small"
          @click="handleReduct"
        >
          还原
        </el-button>
      </div>
      <el-table
        :data="tbody"
        class="thead-light"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          :width="filterForm.tab === 'checking' ? '200' : '150'"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button-group>
              <el-button
                v-if="['checking', 'uncheck'].includes(filterForm.tab)"
                size="small"
                @click="checkedRow(scope.row)"
                type="text"
              >
                通过
              </el-button>
              <el-button
                v-if="filterForm.tab === 'checking'"
                size="small"
                @click="noCheckedRow(scope.row)"
                type="text"
              >
                不通过
              </el-button>
              <el-button
                v-if="filterForm.tab !== 'deleted'"
                size="small"
                @click="configRow(scope.row)"
                type="text"
              >
                编辑
              </el-button>
              <el-button
                v-if="filterForm.tab === 'checked'"
                size="small"
                @click="detailRow(scope.row)"
                type="text"
              >
                详情
              </el-button>
              <el-button
                v-if="filterForm.tab === 'deleted'"
                size="small"
                @click="ReductRow(scope.row)"
                type="text"
              >
                还原
              </el-button>
              <el-button size="small" @click="deleteRow(scope.row)" type="text"
                >删除</el-button
              >
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead[theadKey]">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            :sortable="th.sortable"
          >
            <template slot-scope="scope">
              <el-checkbox
                v-if="th.prop === 'is_recommend' || th.prop === 'is_show'"
                v-model="tbody[scope.$index][th.prop]"
                :true-label="1"
                :false-label="0"
                @change="changeStatus(scope.row, th.prop)"
              >
              </el-checkbox>
              <div v-else-if="th.prop === 'posts'">
                {{ `${scope.row.posts_count}/${scope.row.complaints_count}` }}
              </div>
              <div v-else-if="th.prop === 'complaints'">
                {{
                  `${scope.row.comments_count}/${scope.row.comment_complaints_count}`
                }}
              </div>
              <el-image
                v-else-if="th.prop === 'icon'"
                class="list-icon cursor-pointer"
                :src="fixImageUrl(scope.row[th.prop])"
                @click="$previewImage([scope.row[th.prop]])"
              >
              </el-image>
              <!-- 所属小组 -->
              <span v-else-if="th.prop === 'group'"
                >{{
                  scope.row.groups.map((e) => e.name).join(",") | placeholder
                }}
              </span>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
    <AddTopic
      v-model="openAddTopic"
      :Data="topicConfig"
      :handleType="changeRowType"
      :updateList="refreshList"
    ></AddTopic>
    <NoCheckedDialog
      v-model="openNoCheckedDialog"
      :Data="noCheckData"
      :updateList="refreshList"
    />
  </div>
</template>

<script>
import listFilter from "../../components/topicAdmin/ListFilter";
import Pagination from "@/base/components/Default/Pagination";
import AddTopic from "../../components/topicAdmin/AddTopic";
import NoCheckedDialog from "../../components/topicAdmin/NoCheckedDialog";

import {
  topicList,
  updateShow,
  updateRecommend,
  softDelete,
  Delete,
  putBack,
  check,
} from "../../api/topic-admin/list";
export default {
  data() {
    return {
      tabArr: [
        { label: "已审核", name: "checked" },
        { label: "待审核", name: "checking", badge: 1 },
        { label: "未通过", name: "uncheck" },
        { label: "回收站", name: "deleted" },
      ],
      loading: false, //加载
      thead: {
        checked: [
          // { label: "封面图", prop: "icon", minWidth: 100 },
          { label: "话题名称", prop: "name", minWidth: 130 },
          // { label: "分类", prop: "category_name", minWidth: 110 },
          { label: "所属小组", prop: "group", minWidth: 150 },
          { label: "创建者", prop: "author_name", minWidth: 110 },
          {
            label: "帖子数",
            prop: "posts_count",
            minWidth: 100,
            sortable: true,
          },
          {
            label: "关注数",
            prop: "collects_count",
            minWidth: 100,
            sortable: true,
          },
          {
            label: "浏览数",
            prop: "view_count",
            minWidth: 100,
            sortable: true,
          },
          {
            label: "创建时间",
            prop: "update_time",
            minWidth: 160,
            sortable: true,
          },
          // {
          //   label: "推荐",
          //   prop: "is_recommend",
          //   minWidth: 60,
          // },
          // {
          //   label: "显示",
          //   prop: "is_show",
          //   minWidth: 60,
          // },
        ],
        unchecked: [
          // { label: "封面图", prop: "icon", minWidth: 100 },
          { label: "话题名称", prop: "name", minWidth: 130 },
          // { label: "分类", prop: "category_name", minWidth: 110 },
          { label: "所属小组", prop: "group", minWidth: 150 },
          { label: "简介", prop: "description", minWidth: 160 },
          { label: "创建者", prop: "author_name", minWidth: 110 },
          {
            label: "创建时间",
            prop: "update_time",
            minWidth: 160,
            sortable: true,
          },
        ],
      },
      //筛选对象
      filterForm: {
        group_id: -1,
        category_id: -1,
        name: "", // 话题名称
        author_name: "", // 创建者名称
        recommend_status: -1, // 推荐状态
        create_start_time: -1, //更新开始时间
        create_end_time: -1, //更新结束时间
        tab: "checking", //当前筛选tab
        page_size: 15, //每页多少条数据
      },
      // 筛选缓存
      filters: {
        checked: null,
        checking: null,
        uncheck: null,
        deleted: null,
      },
      pageData: {
        page_size: 15,
        tab: "checked",
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      topicConfig: {},
      noCheckData: {},
      openAddTopic: false, // 开启设置弹窗
      openNoCheckedDialog: false,
      changeRowType: "change",
    };
  },
  computed: {
    theadKey() {
      return ["checking", "uncheck", "deleted"].includes(this.filterForm.tab)
        ? "unchecked"
        : this.filterForm.tab;
    },
  },
  methods: {
    // 获取品牌列表
    getTopicList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      topicList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.selectedMembers = data.user_ids;
          // this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 新增话题
    handleAddTopic() {
      this.topicConfig = {
        id: 0,
        name: "",
        description: "",
        category_id: "",
        group_id: 0,
        with_groups: this.$route.params.id ? [this.$route.params.id] : [],
        icon:
          window.serverConfig.VUE_APP_ADMINURL +
          "/cyc/images/admin/company_logo.png",
        is_show: 1,
        is_recommend: 1,
      };
      this.openAddTopic = true;
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (this.filterForm.tab !== "deleted") {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (this.filterForm.tab !== "deleted") {
        this.$msgbox
          .confirm("确定要将此数据移入回收站吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      } else {
        this.$msgbox
          .confirm("确定要将此数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      if (this.filterForm.tab !== "deleted") {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm("确定要将此数据还原吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.Reduct({ id: row.id });
        })
        .catch((err) => {});
    },
    // 还原数据
    Reduct(id) {
      this.loading = true;
      putBack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击通过
    checkedRow(row) {
      this.$msgbox
        .confirm("确定要通过该数据吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.loading = true;
          check({ id: row.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.refreshList();
            })
            .catch((err) => {
              this.refreshList();
            });
        })
        .catch((err) => {});
    },
    // 批量通过
    handleChecked() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要通过这些数据吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.loading = true;
            check({ id: this.selectArr })
              .then((res) => {
                this.$message.success(res.msg);
                this.refreshList();
              })
              .catch((err) => {
                this.refreshList();
              });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击不通过
    noCheckedRow(row) {
      this.noCheckData = {
        id: row.id,
        reason: "",
      };
      this.openNoCheckedDialog = true;
    },
    // 批量不通过
    handleNoChecked() {
      if (this.selectArr.length) {
        this.noCheckData = {
          id: this.selectArr,
          reason: "",
        };
        this.openNoCheckedDialog = true;
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击编辑
    editRow(row) {
      this.$router.push({
        name: "groupDetail",
        params: { id: row.id },
      });
    },
    // 点击编辑
    configRow(row) {
      this.topicConfig = {
        id: row.id,
        name: row.name,
        description: row.description,
        category_id: row.category_id,
        with_groups: row.with_groups,
        group_id: this.$route.params.id || 0,
        icon:
          window.serverConfig.VUE_APP_ADMINURL +
          "/cyc/images/admin/company_logo.png",
        is_show: row.is_show,
        is_recommend: row.is_recommend,
      };
      this.openAddTopic = true;
    },
    // 点击详情
    detailRow(row) {
      if (this.$route.params.id) {
        this.$router.push({
          name: "groupTopicDetail",
          params: { topic_id: row.id, id: this.$route.params.id },
        });
      } else {
        this.$router.push({
          name: "topicDetail",
          params: { topic_id: row.id },
        });
      }
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          // group_id: this.$route.params.id || -1,
          category_id: -1,
          group_id: -1,
          name: "", // 话题名称
          author_name: "", // 创建者名称
          recommend_status: -1, // 推荐状态
          update_start_time: -1, //更新开始时间
          update_end_time: -1, //更新结束时间
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        };
      }
      this.getTopicList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getTopicList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getTopicList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    refreshList() {
      this.getTopicList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {};
      data.id = e.id;
      data[key] = e[key];
      this.loading = true;
      if (key == "is_recommend") {
        // 更新推荐状态
        updateRecommend(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else if (key === "is_show") {
        // 更新显示状态
        updateShow(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else {
        // 更新排序状态
        updateSort(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      }
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getTopicList(pageData);
    },
  },
  created() {
    // this.getPidList(); //获取上级组织列表
    if (this.$route.params.id) {
      this.filterForm.group_id = this.$route.params.id;
    }
    this.getTopicList(this.filterForm); //获取列表数据
  },
  components: {
    listFilter,
    Pagination,
    AddTopic,
    NoCheckedDialog,
  },
};
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }
  }
}
.list-icon {
  width: 60px;
  height: 60px;
}
.black-member {
  display: flex;
  align-items: center;
  .black-tag {
    margin-left: 10px;
    width: 44px;
    height: 28px;
    background-color: rgb(85, 85, 85);
    font-size: 12px;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.list-icon {
  width: 60px;
  height: 60px;
}
</style>
