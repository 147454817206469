/*
 * @Author: dongjia
 * @Date: 2021-08-16 10:10:49
 * @LastEditTime: 2021-08-16 15:54:35
 * @LastEditors: aleaner
 * @Description: 话题管理接口
 * @FilePath: \saas-admin-vue\src\modules\community-admin\api\topic-admin\list.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";
import download from "@/base/utils/download";

// 获取列表数据
export const topicList = data => {
  return api({
    url: "/admin/circle/topic/index",
    method: "post",
    data
  });
};

// 保存话题数据
export const saveTopic = data => {
  return api({
    url: "/admin/circle/topic/save",
    method: "post",
    data
  });
};

// 更改推荐状态
export const updateRecommend = data => {
  return api({
    url: "/admin/circle/topic/changeRecommend",
    method: "post",
    data
  });
};

// 更改显示状态
export const updateShow = data => {
  return api({
    url: "/admin/circle/topic/changeShow",
    method: "post",
    data
  });
};

// 软删除
export const softDelete = data => {
  return api({
    url: "/admin/circle/topic/softDelete",
    method: "post",
    data
  });
};

// 真删除
export const Delete = data => {
  return api({
    url: "/admin/circle/topic/delete",
    method: "post",
    data
  });
};

// 还原
export const putBack = data => {
  return api({
    url: "/admin/circle/topic/putBack",
    method: "post",
    data
  });
};

// 审核通过
export const check = data => {
  return api({
    url: "/admin/circle/topic/check",
    method: "post",
    data
  });
};

// 审核不通过
export const uncheck = data => {
  return api({
    url: "/admin/circle/topic/uncheck",
    method: "post",
    data
  });
};

// 话题选项列表
export const topicOptions = data => {
  return api({
    url: "/admin/circle/topic/all",
    method: "post",
    data
  });
};

// 导出列表数据
export const topicsExport = data => {
  return download({
    url: "/admin/circle/topic/document",
    method: "post",
    data,
    download: true
  });
};